import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import withStyles from 'react-jss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../CommonStyles';
import { clearEmployeeLedger, getEmployeeLedger } from '../Actions';
import { get } from '../utils';

const { Text } = Typography;

const Ledger = props => {
  const { classes, employeeId, Reference } = props;
  const dispatch = useDispatch();

  const employeeLedger = useSelector(state => state.employeeLedger);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeLedger(employeeId));
    }
    return () => {
      dispatch(clearEmployeeLedger());
    };
  }, [employeeId]);

  const renderAdvance = record => {
    if (
      record.narration === 'Salary Advance (Cash)' ||
      record.narration === 'Salary Advance (Bank)' ||
      record.narration === 'Total Wages Paid to Employees (Bank)'
    ) {
      return Number(record.amount).toLocaleString('en-IN');
    }
  };

  const renderSalary = record => {
    if (record.narration === 'Salary Office (Cash)' || record.narration === 'Salary Office (Bank)') {
      return Number(record.amount).toLocaleString('en-IN');
    }
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'ts',
      render: ts => (ts ? moment(ts).format('DD MMM YYYY') : null),
    },
    {
      title: 'Description',
      dataIndex: 'narration',
    },
    {
      title: 'Advance',
      render: renderAdvance,
    },
    {
      title: 'Salary',
      render: renderSalary,
    },
  ];

  const employeeBalance = get(employeeLedger, ['employeeBalance'], 0);
  const advanceTotal = get(employeeLedger, ['advanceTotal'], 0);
  const salaryTotal = get(employeeLedger, ['salaryTotal'], 0);
  // const balance = +salaryTotal - +advanceTotal - +employeeBalance;
  const salaryToBeGiven = get(employeeLedger, ['salaryToBeGiven'], 0);

  return (
    <div ref={Reference}>
      <div className={classes.textAlignCenter}>{get(employeeLedger, ['employee', 'name'], '--')} Ledger</div>
      <div className={classes.textAlignCenter}>
        {get(employeeLedger, ['employee', 'joiningDate'])
          ? moment(get(employeeLedger, ['employee', 'joiningDate'])).format('DD MMM YYYY')
          : null}{' '}
        to {moment().format('DD MMM YYYY')}
      </div>
      <Table
        columns={columns}
        bordered
        rowKey={record => record.index}
        pagination={false}
        dataSource={get(employeeLedger, ['statements'], [])}
        size="small"
        scroll={{ x: 425 }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>Opening Balance</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text>{Number(employeeBalance.toFixed(2)).toLocaleString('en-IN')}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text>{Number(advanceTotal.toFixed(2)).toLocaleString('en-IN')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text>{Number(salaryTotal.toFixed(2)).toLocaleString('en-IN')}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>Closing Balance</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text>{Number(get(employeeLedger, ['closing'], 0).toFixed(2)).toLocaleString('en-IN')}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>Salary To be paid this month till now</Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text>{Number(salaryToBeGiven).toLocaleString('en-IN')}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

Ledger.propTypes = {
  employeeId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  Reference: PropTypes.any,
};

export default withStyles(styles)(Ledger);
