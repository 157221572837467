import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import startCase from 'lodash/startCase';
import { Col, Row, Select, Table } from 'antd';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { clearMaterialIdList, clearMaterialTransactions, getMaterialIdList, getMaterialTransactions } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import GetField from '../Components/GetField';
import constants from '../constants';

const AntSelect = GetField(Select);

const MaterialTransaction = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const materialIdList = useSelector(state => state.materialIdList);
  const materialTransactions = useSelector(state => state.materialTransactions);

  const FormValues = useSelector(state => getFormValues('MaterialTransaction')(state));

  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    dispatch(getMaterialIdList());
    return () => {
      dispatch(clearMaterialIdList());
      dispatch(clearMaterialTransactions());
    };
  }, []);

  useEffect(() => {
    if (Consignor && FormValues && FormValues.materialId) {
      dispatch(getMaterialTransactions({ consignor: Consignor, materialId: FormValues.materialId }));
    }
    return () => {
      dispatch(clearMaterialTransactions());
    };
  }, [Consignor, FormValues && FormValues.materialId]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      onFilter: onFilter('type'),
      filterDropdown,
      render: type => startCase(type),
    },
    {
      title: 'Date',
      dataIndex: 'ts',
      sorter: sorter('ts'),
      filterDropdown,
      onFilter: onFilter('ts'),
      render: ts => (ts ? moment(ts).format('DD MMM YY') : null),
    },
    {
      title: 'Voucher Type',
      dataIndex: 'voucherType',
      sorter: sorter('voucherType'),
      onFilter: onFilter('voucherType'),
      filterDropdown,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: sorter('title'),
      onFilter: onFilter('title'),
      filterDropdown,
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      sorter: sorter('batch'),
      onFilter: onFilter('batch'),
      filterDropdown,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      onFilter: onFilter('quantity'),
      filterDropdown,
      render: text => Number(text).toFixed(4),
    },
    {
      title: 'Initial',
      dataIndex: 'initial',
      sorter: sorter('initial'),
      onFilter: onFilter('initial'),
      filterDropdown,
      render: text => Number(text).toFixed(4),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: sorter('balance'),
      onFilter: onFilter('balance'),
      filterDropdown,
      render: text => Number(text).toFixed(4),
    },
    {
      title: 'Remark',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      onFilter: onFilter('remarks'),
      filterDropdown,
    },
    {
      title: 'Done By',
      dataIndex: 'user',
      filterDropdown,
      sorter: sorter('user'),
      onFilter: onFilter('user'),
    },
  ];

  return (
    <Structure>
      <>
        <Row gutter={[8, 8]}>
          {/* <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col> */}
          <Col>
            <div className={classes.selectListWidth}>
              <Field allowClear required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Field>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={materialTransactions}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

MaterialTransaction.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledMaterialTransaction = withStyles(styles)(MaterialTransaction);

const FormedMaterialTransaction = reduxForm({
  form: 'MaterialTransaction',
  enableReinitialize: true,
})(StyledMaterialTransaction);

export default FormedMaterialTransaction;
