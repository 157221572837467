import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { clearEmployeeSalaryRecords, getEmployeeSalaryRecords } from '../Actions';
import { dailyWorkingMinutes } from '../constants';
import { errorNotification } from '../Snackbar';
import Api from '../Api';

const Record = props => {
  const { employeeId } = props;
  const dispatch = useDispatch();

  const employeeSalaryRecord = useSelector(state => state.employeeSalaryRecord);

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeSalaryRecords(employeeId));
    }
    return () => {
      dispatch(clearEmployeeSalaryRecords());
    };
  }, [employeeId]);

  const exportSalaryRecord = async () => {
    try {
      const { data } = await Api.getFile(`/employees/${employeeId}/details/export`);
      fileDownload(data, `SalaryRecord-${employeeId}.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const columns = [
    {
      title: 'Month',
      dataIndex: 'month',
      render: month => (month ? moment(month).format('MMM YYYY') : null),
    },
    {
      title: 'Working (min)',
      dataIndex: 'actualWorkingMinute',
    },
    {
      title: 'Overtime Week Days (min)',
      dataIndex: 'overtimeMinuteNormal',
    },
    {
      title: 'Overtime Sunday (min)',
      dataIndex: 'overtimeMinuteSunday',
    },
    {
      title: 'Overtime Holiday (min)',
      dataIndex: 'overtimeMinuteHoliday',
    },
    {
      title: 'Bonus/Late (min)',
      dataIndex: 'lateMinute',
    },
    {
      title: 'Sundays worked (day)',
      dataIndex: 'sundaysWorked',
    },
    {
      title: 'Paid Sundays (day)',
      dataIndex: 'sundaysToBePaid',
    },
    {
      title: 'Holidays Worked (day)',
      dataIndex: 'holidaysWorked',
    },
    {
      title: 'Paid Holidays (day)',
      dataIndex: 'paidHolidays',
    },
    {
      title: 'Present (day)',
      dataIndex: 'totalPresent',
    },
    {
      title: 'Absent (day)',
      dataIndex: 'totalAbsent',
    },
    {
      title: 'Unapproved Leaves',
      dataIndex: 'unapprovedLeaves',
    },
    {
      title: 'Extra Day Paid (day)',
      dataIndex: 'extraDayPaid',
    },
    {
      title: 'Salary (Rs.)',
      dataIndex: 'salary',
    },
    {
      title: 'Salary / Day (Rs.)',
      dataIndex: 'salaryPerMinute',
      render: text => Number(Number(text) * Number(dailyWorkingMinutes)).toFixed(2),
    },
    {
      title: 'Salary To Be Paid (Rs.)',
      dataIndex: 'salaryToBeGiven',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        bordered
        rowKey={record => record.id}
        pagination={false}
        dataSource={employeeSalaryRecord}
        size="small"
        scroll={{ x: 1440, y: window.innerHeight - 300 }}
      />
      <br />
      <Row>
        <Button type="default" onClick={exportSalaryRecord}>
          Export
        </Button>
      </Row>
    </>
  );
};

Record.propTypes = {
  employeeId: PropTypes.number,
};

export default Record;
